<template>
  <b-nav-item-dropdown
    :html="selectedLocale && selectedLocale.code"
    right
    class="lang-switcher"
  >
    <template #button-content>
      <span class="d-inline-flex align-items-center justify-content-start">
        <b-img
          :src="`/i18n/${selectedLocale.code}.svg`"
          :alt="selectedLocale.name"
          rounded
          width="20"
          height="15"
        />
      </span>
    </template>
    <b-dropdown-item
      v-for="locale in availableLocales"
      :key="locale.code"
      href="#"
      @click.prevent.stop="$i18n.setLocale(locale.code)"
    >
      <b-img
        :src="`/i18n/${locale.code}.svg`"
        :alt="locale.name"
        rounded
        width="20"
        height="15"
      />
      {{ locale.name }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: 'LocaleSwitcher',
  computed: {
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    selectedLocale () {
      return this.$i18n.locales.find(i => i.code === this.$i18n.locale)
    }
  }
}
</script>

<style>
  .lang-switcher {
    display: flex;
    align-items: center;
  }
</style>
