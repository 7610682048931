<template>
  <div>
    <b-btn
      v-if="type === 'check-support' && options && options.talk_id"
      variant="info"
      size="sm"
      :disabled="assignedTo && !assignedToSelf"
      @click="onShowSupportTalk"
    >
      {{ assignedTo !== null ? $t('eLt_taskActions_showTalk') : $t('eLt_taskActions_manageTalk') }}
    </b-btn>
    <talk-btn
      v-if="type === 'check-message-media' && options && options.talk_id"
      variant="info"
      size="sm"
      :talk="options.talk_id"
      :validate="onPreviewTalk"
    >
      {{ $t('eLt_taskActions_checkTalk') }}
    </talk-btn>
  </div>
</template>

<script>
export default {
  name: 'TaskActions',
  props: {
    taskId: {
      type: [Number, String],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    assignedTo: {
      type: [Number, String, null],
      default: null
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    assignedToSelf () {
      return this.assignedTo && this.$auth.user.userId === this.assignedTo // userId
    }
  },
  methods: {
    async onShowSupportTalk () {
      if (this.options && this.options.talk_id) {
        const res = await this.$taskAssign(this.taskId)
        if (res) {
          this.$emit('update')
          this.$supportChat(this.options.talk_id)
        }
      }
    },
    onPreviewTalk () {
      // if (this.assignedTo === null) {
      //   const res = await this.$taskAssign(this.taskId)
      //   if (res) {
      //     this.$emit('update')
      //   }
      //   return res
      // }
      return true
    }
  }
}
</script>

<style scoped>

</style>
