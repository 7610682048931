<template>
  <b-btn
    v-if="isExists"
    variant="info"
    size="sm"
    @click.stop.prevent="onClick"
  >
    <slot>
      Talk {{ talkId }}
    </slot>
  </b-btn>
</template>

<script>
export default {
  name: 'TalkBtn',
  props: {
    talk: {
      type: [Object, Number, String],
      default: () => ({})
    },
    validate: {
      type: Function,
      default: () => true
    }
  },
  computed: {
    isExists () {
      return !!this.talkId
    },
    talkId () {
      return typeof this.talk === 'object'
        ? this.talk && this.talk.id
        : this.talk
    }
  },
  methods: {
    async onClick () {
      const res = await this.validate()
      if (res) {
        this.$openGlobal('TalkPreviewForm', this.talkId, 'talks')
      }
    }
  }
}
</script>

<style scoped>

</style>
