<template>
  <div v-if="state">
    <div class="text-info mb-1 cursor-pointer" @click="onCollapse">
      Show less
    </div>
    <pre class="bg-secondary py-1 px-1">{{ expanded }}</pre>
  </div>
  <div
    v-else
    style="width: 600px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
    class="cursor-pointer"
    @click="onExpand"
  >
    {{ value }}
  </div>
</template>

<script>
export default {
  name: 'DebugInfoCollapse',
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: ''
    }
  },
  data () {
    return {
      state: false
    }
  },
  computed: {
    expanded () {
      return JSON.parse(this.value) || {}
    }
  },
  methods: {
    onExpand () {
      this.state = true
    },
    onCollapse () {
      this.state = false
    }
  }
}
</script>

<style scoped>

</style>
