<template>
  <div class="col-auto">
    <div class="media-transform d-flex justify-content-around">
      <button
        id="transform-media-btn-left-14"
        data-id="14"
        data-old_id="14"
        data-action="left"
        class="btn transform-media-btn p-0"
        @click="onTransform('left')"
      >
        <font-awesome-icon
          icon="rotate-left"
        />
      </button>
      <button
        id="transform-media-btn-flipY-14"
        data-id="14"
        data-old_id="14"
        data-action="flipY"
        class="btn transform-media-btn p-0"
        @click="onTransform('flipY')"
      >
        <font-awesome-icon
          icon="up-down"
        />
      </button>
      <button
        id="transform-media-btn-flipX-14"
        data-id="14"
        data-old_id="14"
        data-action="flipX"
        class="btn transform-media-btn p-0"
        @click="onTransform('flipX')"
      >
        <font-awesome-icon
          icon="left-right"
        />
      </button>
      <button
        id="transform-media-btn-right-14"
        data-id="14"
        data-old_id="14"
        data-action="right"
        class="btn transform-media-btn p-0"
        @click="onTransform('right')"
      >
        <font-awesome-icon
          icon="rotate-right"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MediaTransform',
  props: {
    image: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    ...mapActions({
      transform: 'media/transform'
    }),
    async onTransform (action) {
      await this.transform({ id: this.image.id, action })
      this.$emit('on-updated', this.image.id)
    }
  }
}
</script>

<style scoped>

</style>
