import { render, staticRenderFns } from "./DebugInfoCollapse.vue?vue&type=template&id=8cf30e70&scoped=true&"
import script from "./DebugInfoCollapse.vue?vue&type=script&lang=js&"
export * from "./DebugInfoCollapse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8cf30e70",
  null
  
)

export default component.exports