<template>
  <b-link
    v-if="isExists"
    :class="`text-${variant}`"
    @click.stop.prevent="onClick"
  >
    <slot>
      Talk {{ talk.id }}
    </slot>
  </b-link>
</template>

<script>
export default {
  name: 'TalkLink',
  props: {
    talk: {
      type: Object,
      default: () => ({})
    },
    variant: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExists () {
      return this.talk && this.talk.id
    }
  },
  methods: {
    onClick () {
      this.$openGlobal('TalkPreviewForm', this.talk.id, 'talks')
      // this.$emit('click', this.talk.id)
    }
  }
}
</script>

<style scoped>

</style>
