<template>
  <b-img
    v-if="market"
    :src="`/i18n/${market}.svg`"
    :alt="market"
    :title="market.toLocaleUpperCase()"
    width="20"
    height="15"
  />
</template>

<script>
export default {
  name: 'MarketFlag',
  props: {
    market: {
      type: String,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
