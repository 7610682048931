<template>
  <div class="d-flex flex-column align-items-end">
    <v-select
      v-model="newOption"
      :options="activeOptions"
      :searchable="true"
      :label-title="labelTitle"
      :label-not-found="labelNotFound"
      :label-search-placeholder="labelSearchPlaceholder"
      :text-prop="textProp"
      :value-prop="valueProp"
      style="width: 180px"
      :disabled="disabled"
    />
    <div
      v-if="selected && selected.length"
      class="mt-2 d-flex flex-row align-items-end justify-content-end flex-wrap"
    >
      <b-badge
        v-for="(option, i) in selected"
        :key="i"
        variant="info"
        class="m-1"
      >
        {{ getOptionName(option) }}
        <font-awesome-icon
          v-if="!disabled"
          :icon="['fas', 'xmark']"
          size="sm"
          style="cursor: pointer"
          @click="onRemoveSelected(option)"
        />
      </b-badge>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Multiselect',
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Array,
      default: () => ([])
    },
    textProp: { type: String, default: 'text' },
    valueProp: { type: String, default: 'value' },
    labelTitle: { type: String, default: 'Select value' },
    labelNotFound: { type: String, default: 'No results matched' },
    labelSearchPlaceholder: { type: String, default: 'Search' },
    disabled: { type: Boolean, default: false }
  },
  data () {
    return {
      newOption: null,
      selected: this.value
    }
  },
  computed: {
    activeOptions () {
      return this.selected
        ? [
            ...this.options
          ]
            .filter(o => !this.selected.includes(`${o.value}`) && !this.selected.includes(o.value))
            .map(i => ({
              value: `${i.value}`,
              text: i.text
            }))
        : []
    }
  },
  watch: {
    value (n) {
      if (n && JSON.stringify(n) !== JSON.stringify(this.selected)) {
        this.selected = n
      }
    },
    newOption: {
      handler (n) {
        if (n) {
          const newValue = [...this.selected]
          newValue.push(n.value)
          this.$emit('input', newValue)
          this.$nextTick(() => {
            this.newOption = null
            this.$forceUpdate()
          })
        }
      },
      deep: true
    }
  },
  methods: {
    getOptionName (id) {
      const o = this.options.find(i => `${i.value}` === `${id}`)
      return o && o.text
    },
    onRemoveSelected (id) {
      this.selected = [...this.selected].filter(i => `${i}` !== `${id}`)
      this.$emit('input', this.selected)
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    }
  }
}
</script>

<style scoped>

</style>
