<template>
  <li v-if="customersOnlineCount" class="nav-item d-flex align-items-center px-1 px-lg-3">
    <small v-shtml="$t('eLt_coustomersOnlineCount')" />
    <span class="h3 m-0 ml-3">{{ displayCount }}</span>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomersOnline',
  computed: {
    ...mapGetters({
      customersOnlineCount: 'staffUser/customersOnlineCount'
    }),
    displayCount () {
      let mod = ''
      let val = this.customersOnlineCount
      if (val > 999999) {
        val = Math.floor(val / 1000000)
        mod = 'M'
      } else if (val > 999) {
        val = Math.floor(val / 1000)
        mod = 'k'
      }
      return `${val}${mod}`
    }
  }
}
</script>

<style scoped>

</style>
