<template>
  <span>
    <b-link
      v-if="isExists"
      :id="`affiliate-link-${uniqKey}`"
      :class="isLocked('affiliates', affiliate && affiliate.id) ? 'text-secondary' : ''"
      @click.prevent.stop="onClick"
    >
      <slot>
        {{ affiliate.slug }}
      </slot>
    </b-link>
    <b-tooltip
      v-if="isLocked('affiliates', affiliate && affiliate.id) && isExists"
      :target="`affiliate-link-${uniqKey}`"
      placement="bottom"
      variant="warning"
      class="text-left"
    >
      {{ $t('eLt_locked', { email: isLockedByUser.email, id: isLockedByUser.by }) }}
    </b-tooltip>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AffiliateLink',
  props: {
    affiliate: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      isLocked: 'shared/isLocked',
      isLockedBy: 'shared/isLockedBy'
    }),
    isExists () {
      return this.affiliate && this.affiliate.id
    },
    uniqKey () {
      return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 8)
    },
    isLockedByUser () {
      const user = this.isLockedBy('affiliates', this.affiliate && this.affiliate.id)
      if (user) {
        return user
      }
      return false
    }
  },
  methods: {
    onClick () {
      this.$openGlobal('AffiliateEditForm', this.affiliate.id, 'affiliates')
      // this.$emit('click', this.affiliate.id)
    }
  }
}
</script>

<style scoped>

</style>
