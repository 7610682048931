<template>
  <transition name="fade">
    <div v-show="loadingState" class="loading-page">
      <p class="label">
        {{ $t('eLoading_loading') }}...
      </p>
      <spinner />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoadingBar',
  manual: true,
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters([
      'loadingEnable',
      'loadingManual'
    ]),
    loadingState () {
      return this.loadingEnable && this.loading
    }
  },
  watch: {
    loadingManual (n) {
      if (n) {
        this.startManual()
      } else {
        this.finishManual()
      }
    }
  },
  methods: {
    start () {
      if (!this.loadingManual) {
        this.loading = true
      }
    },
    finish () {
      if (!this.loadingManual) {
        this.loading = false
      }
    },
    startManual () {
      this.loading = true
    },
    finishManual () {
      this.loading = false
    }
  }
}
</script>

<style scoped>
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding-top: 200px;
  font-size: 30px;
  font-family: sans-serif;
  z-index: 9999;
}

.label {
  margin: 100px auto 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.15s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
