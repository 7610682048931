<template>
  <section class="py-5">
    <ul class="timeline">
      <li
        v-for="(item, i) in items"
        :key="i"
        class="timeline-item mb-5"
      >
        <slot name="default" :data="{item, index: i}" />
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'Timeline',
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style scoped>
.timeline {
  border-left: 1px solid #7386D5;
  position: relative;
  list-style: none;
  /*padding-left: 68px;*/
  margin-left: 24px;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: #7386D5;
  left: -46px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}
</style>
